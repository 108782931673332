<template>
  <div class="dashboard-content">
    <!--begin::Dashboard-->
    <div class="row mb-5">
      <div class="col-lg-6 cards-container">
        <b-row>
          <b-col @click="changeChart('Customer')" cols="3">
            <!-- <input :value= stats.totalOrders /> -->
            <InfoCard
              iconClass="fa-users"
              title="Customers"
              :value="stats.totalCustomers"
              subText="Increased By 4%"
          /></b-col>
          <b-col @click="changeChart('Orders')" cols="3">
            <InfoCard
              iconClass="fa-shopping-bag"
              title="Orders"
              :value="stats.totalOrders"
              subText="Increased By 4%"
            />
            <!-- <input :value="stats.totalOrders" style="color: red" /> -->
          </b-col>
          <b-col cols="3" @click="changeChart('Delivery')"
            ><InfoCard
              iconClass="fa-car"
              title="Delivery"
              :value="stats.totalDeliveries"
              subText="Increased By 4%"
          /></b-col>
          <b-col cols="3" @click="changeChart('User')">
            <InfoCard
              iconClass="fa-check"
              title="Users"
              :value="stats.totalUsers"
              subText="Increased By 4%"
          /></b-col>
        </b-row>
        <b-row v-if="region === 'PAKISTAN'">
          <b-col cols="3">
            <InfoCard
              @click="changeChart('Revenue')"
              iconClass="fa-money-bill"
              title="Revenue"
              :value="parseFloat(stats.revenue.total)"
              isMoney="true"
          /></b-col>
          <b-col cols="3">
            <InfoCard
              @click="changeChart('Avg.Rating')"
              iconClass="fa-star"
              title="Avg.Rating"
              :value="stats.avgRating"
          /></b-col>
          <b-col cols="3"
            ><InfoCard
              @click="changeChart('Avg.Revenue Per Customer')"
              iconClass="fa-money-bill"
              title="Avg.Revenue Per Customer"
              :value="parseFloat(stats.revenue.perCustomer)"
              isMoney="true"
              subText="Increased By 4%"
          /></b-col>
          <b-col cols="3"
            ><InfoCard
              @click="changeChart('Avg.Revenue Per User')"
              iconClass="fa-money-bill"
              title="Avg.Revenue Per User"
              :value="parseFloat(stats.revenue.perUser)"
              isMoney="true"
          /></b-col>
        </b-row>
        <b-row v-if="region === 'US'">
          <b-col cols="3">
            <InfoCard
              @click="changeChart('Revenue')"
              iconClass="fa-money-bill"
              title="Revenue"
              :value="stats.revenue.total"
              isMoney="true"
          /></b-col>
          <b-col cols="3">
            <InfoCard iconClass="fa-star" title="Avg.Rating" :value="4.0"
          /></b-col>
          <b-col cols="3"
            ><InfoCard
              @click="changeChart('Avg.Revenue Per Customer')"
              iconClass="fa-money-bill"
              title="Avg.Revenue Per Customer"
              :value="stats.revenue.perCustomer"
              isMoney="true"
              subText="Increased By 4%"
          /></b-col>
          <b-col cols="3"
            ><InfoCard
              @click="changeChart('Avg.Revenue Per User')"
              iconClass="fa-money-bill"
              title="Avg.Revenue Per User"
              :value="stats.revenue.perUser"
              isMoney="true"
          /></b-col>
        </b-row>
      </div>
      <div class="col-lg-6">
        <div class="graph-container" v-if="chartData !== null">
          <h2>{{ chartData.type }} Statistics</h2>
          <apexchart
            type="area"
            height="270"
            :options="chartOptions"
            :series="series"
          ></apexchart>
          <div class="graph-stats">
            <div class="stats-box">
              <span class="stats-title">Daily {{ chartData.type }}</span>
              <span class="stats-content">{{ chartData.daily }}</span>
            </div>
            <div class="stats-box">
              <div class="stats-title">Weekly {{ chartData.type }}</div>
              <div class="stats-content">{{ chartData.weekly }}</div>
            </div>
            <div class="stats-box">
              <div class="stats-title">Trend(Prev 7 days)</div>
              <div class="stats-content">
                <i :class="`fas ${chartData.trend}`"></i>
                <span>{{ chartData.lastWeek }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="graph-container" v-else>Loading...</div>
      </div>
    </div>
    <!-- {{stats.revenue.topCustomer.orders}} {{stats.revenue.topRider.orders}} {{stats.revenue.topStore.orders}} -->
    <div v-if="region === 'PAKISTAN'" class="row">
      <template
        v-if="
          stats.revenue.topCustomer !== null &&
          stats.revenue.topCustomer.customer !== null
        "
      >
        <div class="col-lg-4 h-100">
          <TopUserCard
            type="Customer"
            v-if="stats.revenue.topCustomer"
            :value="stats.revenue.topCustomer.amountSpent"
            :firstName="stats.revenue.topCustomer.customer.name"
            :infoText="{
              text: 'Rating',
              ratingValue: 4,
              value: stats.revenue.topCustomer.amountSpent.toString(),
              trend: 'positive',
              trendValue: '12',
              processedOrders: stats.revenue.topCustomer.orders,
              processedOrdersTrend: 'negative',
              processedOrdersTrendValue: '8',
            }"
          />
        </div>
      </template>
      <template
        v-if="
          stats.revenue.topRider !== null &&
          stats.revenue.topRider.rider !== null
        "
      >
        <div class="col-lg-4 h-100">
          <TopUserCard
            type="Rider"
            :value="stats.revenue.topRider.riderEarnings"
            :firstName="stats.revenue.topRider.rider.name"
            :infoText="{
              text: 'Rating',
              ratingValue: 4,
              trend: 'positive',
              trendValue: '12',
              processedOrders: stats.revenue.topRider.orders,
              processedOrdersTrend: 'positive',
              processedOrdersTrendValue: '8',
            }"
          />
        </div>
      </template>
      <template
        v-if="
          stats.revenue.topStore !== null &&
          stats.revenue.topStore.store !== null
        "
      >
        <div class="col-lg-4 h-100">
          <TopUserCard
            type="Seller"
            :value="stats.revenue.topStore.amountEarned"
            :firstName="stats.revenue.topStore.store.storeName"
            :infoText="{
              text: 'Rating',
              ratingValue: 4,
              trend: 'negative',
              trendValue: '12',
              processedOrders: stats.revenue.topStore.orders,
              processedOrdersTrend: 'positive',
              processedOrdersTrendValue: '8',
            }"
          />
        </div>
      </template>
    </div>
    <div v-if="region === 'US'" class="row">
      <template v-if="stats.revenue.topCustomer !== null">
        <div class="col-lg-4 h-100">
          <TopUserCard
            type="Customer"
            value="49,137"
            :firstName="stats.revenue.topCustomer.customer.name"
            :infoText="{
              text: 'Amount Spent',
              value: stats.revenue.topCustomer.amountSpent.toString(),
              trend: 'positive',
              trendValue: '12',
              processedOrders:
                stats.revenue.topCustomer.baskytEarning.toString(),
              processedOrdersTrend: 'negative',
              processedOrdersTrendValue: '8',
            }"
          />
        </div>
      </template>

      <template v-if="stats.revenue.topRider !== null">
        <div class="col-lg-4 h-100">
          <TopUserCard
            type="Rider"
            value="49,137"
            :firstName="stats.revenue.topRider.rider.name"
            :infoText="{
              text: 'Rating',
              ratingValue: 4,
              trend: 'positive',
              trendValue: '12',
              processedOrders: '152',
              processedOrdersTrend: 'positive',
              processedOrdersTrendValue: '8',
            }"
          />
        </div>
      </template>
      <template v-if="stats.revenue.topStore !== null">
        <div class="col-lg-4 h-100">
          <TopUserCard
            type="Seller"
            value="49,137"
            :firstName="stats.revenue.topStore.store.storeName"
            :infoText="{
              text: 'Revenue',
              value: '13469.80',
              trend: 'negative',
              trendValue: '12',
              processedOrders: '132',
              processedOrdersTrend: 'positive',
              processedOrdersTrendValue: '8',
            }"
          />
        </div>
      </template>
    </div>
    <template v-if="region === 'US'">
      <orders-table-us tableTitle="US Active Orders" :orderCall="callOrder"
    /></template>
    <template v-else-if="region === 'PAKISTAN'" :orderCall="callOrder">
      <orders-table-pk tableTitle="PK Active Orders" :orderCall="callOrder" />
    </template>
  </div>
  <!--end::Dashboard-->
</template>
<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

import {
  FETCH_ACTIVE_ORDERS,
  FETCH_GRAPH_DATA,
} from "@/core/services/store/activeOrders.module";
import { mapState, mapGetters } from "vuex";
import InfoCard from "@/view/layout/dashboard_components/InfoCard";
import ApiService from "@/core/services/api.service";
import TopUserCard from "@/view/layout/dashboard_components/TopUserCard";
import OrdersTableUS from "@/view/pages/orders/us/OrdersTable";
import OrdersTablePK from "@/view/pages/orders/pk/OrdersTable";
export default {
  name: "dashboard",
  components: {
    "orders-table-us": OrdersTableUS,
    "orders-table-pk": OrdersTablePK,
    InfoCard,
    TopUserCard,
  },
  data() {
    return {
      callOrder: FETCH_ACTIVE_ORDERS,
      cancelOrderConfirm: -1,
      cancelling: false,
      acticeOrderindex: null,
      storeNamesToll: "",
      fetchingStats: true,
      modalData: null,
      perPage: 10,
      currentPage: 1,
      chartData: null,
      // {
      //   type: "Customer",
      //   weekly: "8,210",
      //   monthly: "241,894"
      // }
      series: [
        // {
        //   name: "No. of users",
        //   data: [10, 41, 35, 51, 49, 62, 69, 91, 148]
        // }
      ],
      chartOptions: {
        chart: {
          height: "auto",
          type: "area",
          zoom: {
            enabled: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "straight",
        },
        title: {
          text: "",
          align: "left",
          margin: 10,
          offsetY: 10,
          offsetX: 10,
        },
        grid: {
          row: {
            colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
            opacity: 0.5,
          },
        },
        xaxis: {
          categories: [],
        },
      },
      tableFieldsPK: [
        {
          key: "Type",
          sortable: true,
        },
        {
          key: "Order",
          sortable: true,
        },
        {
          key: "Name",
          label: "Customer",
          sortable: true,
        },
        {
          key: "Order_Time",
          sortable: true,
        },
        {
          key: "Number_of_Retries",
          sortable: true,
        },
        {
          key: "Vendors",
          sortable: true,
        },
        {
          key: "Rider",
          sortable: true,
        },
        {
          key: "Order_Amount",
          sortable: true,
        },
        {
          key: "Status",
          label: "Performance",
          sortable: true,
        },
        {
          key: "Cancel",
          label: "Cancel",
          sortable: true,
        },
      ],

      stats: {
        totalCustomers: 0,
        totalOrders: 0,
        totalDeliveries: 0,
        totalUsers: 0,
        revenue: {
          perCustomer: 0,
          perUser: 0,
          total: 0,
          topCustomer: null,
          topRider: null,
          topStore: null,
        },
        avgRating: 0,
      },
    };
  },
  computed: {
    ...mapState({
      region: ({ region }) => region.region,
      graphDeliveries: ({ activeOrders }) => activeOrders.graphDeliveries,
      graphOrders: ({ activeOrders }) => activeOrders.graphOrders,
      graphUsers: ({ activeOrders }) => activeOrders.graphUsers,
      graphCustomers: ({ activeOrders }) => activeOrders.graphCustomers,
      fetchingGraph: ({ activeOrders }) => activeOrders.fetchingGraph,
    }),
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Dashboard" }]);
    ApiService.get("/getStats")
      .then(({ data: response }) => {
        if (response.success) {
          const data = {
            totalCustomers: response.totalCustomers,
            totalOrders: response.totalOrders,
            totalDeliveries: response.totalDeliveries,
            totalUsers: response.totalUsers,
            revenue: response.revenue,
            avgRating: response.avgRating,
          };
          this.stats = data;
        }
      })
      .catch((error) => {
        console.log(error);
      });
    this.$store.dispatch(FETCH_GRAPH_DATA).then((resp) => {
      if (resp) {
        this.changeChart("Orders");
      }
    });
  },
  methods: {
    ...mapGetters(["allOrders", "allOrdersCount", "getOrdersLoading"]),
    cancelOrder(idx) {
      this.cancelOrderConfirm = idx;
      console.log(
        "cancelOrder | this.cancelOrderConfirm",
        this.cancelOrderConfirm
      );
      setTimeout(() => {
        this.$refs.cancelOrderModal.show();
      }, 200);
    },
    updateOrders() {
      this.$store.dispatch(FETCH_ACTIVE_ORDERS, [
        (this.currentPage - 1) * this.perPage,
        this.perPage,
      ]);
    },
    changeChart(type) {
      if (type === "Orders") {
        this.chartOptions.xaxis.categories = this.graphOrders.orders.map(
          (go) => go.day
        );
        const { lastWeekOrders } = this.graphOrders;
        const weekly = this.graphOrders.orders.reduce(
          (acc, it) => acc + it.count,
          0
        );
        this.chartData = {
          type: type,
          daily: (weekly / 7).toFixed(1).toString(),
          weekly: weekly.toString(),
          lastWeek: lastWeekOrders,
        };
        if (weekly === lastWeekOrders) {
          this.chartData.trend = "fa-grip-lines text-info";
        } else if (weekly > lastWeekOrders) {
          this.chartData.trend = "fa-arrow-circle-up text-success";
        } else {
          this.chartData.trend = "fa-arrow-circle-down text-danger";
        }
        this.series = [];
        this.series.push({
          name: `No. of ${type}`,
          data: this.graphOrders.orders.map((go) => go.count),
        });
      } else if (type === "User") {
        console.log(
          "file: Dashboard.vue | line 501 | changeChart | graphUsers",
          this.graphUsers
        );
        this.chartOptions.xaxis.categories = this.graphUsers.users.map(
          (go) => go.day
        );

        const { lastWeekUsers } = this.graphUsers;
        const weekly = this.graphUsers.users.reduce(
          (acc, it) => acc + it.count,
          0
        );
        this.chartData = {
          type: type,
          daily: (weekly / 7).toFixed(1).toString(),
          weekly: weekly.toString(),
          lastWeek: lastWeekUsers,
        };
        if (weekly === lastWeekUsers) {
          this.chartData.trend = "fa-grip-lines text-info";
        } else if (weekly > lastWeekUsers) {
          this.chartData.trend = "fa-arrow-circle-up text-success";
        } else {
          this.chartData.trend = "fa-arrow-circle-down text-danger";
        }
        this.series = [];
        this.series.push({
          name: `No. of ${type}`,
          data: this.graphUsers.users.map((go) => go.count),
        });
        // this.chartOptions.title.text = "Customer Statistics";
      } else if (type === "Customer") {
        console.log(
          "file: Dashboard.vue | line 501 | changeChart | graphUsers",
          this.graphCustomers
        );
        this.chartOptions.xaxis.categories = this.graphCustomers.users.map(
          (go) => go.day
        );

        const { lastWeekUsers } = this.graphCustomers;
        const weekly = this.graphCustomers.users.reduce(
          (acc, it) => acc + it.count,
          0
        );
        this.chartData = {
          type: type,
          daily: (weekly / 7).toFixed(1).toString(),
          weekly: weekly.toString(),
          lastWeek: lastWeekUsers,
        };
        if (weekly === lastWeekUsers) {
          this.chartData.trend = "fa-grip-lines text-info";
        } else if (weekly > lastWeekUsers) {
          this.chartData.trend = "fa-arrow-circle-up text-success";
        } else {
          this.chartData.trend = "fa-arrow-circle-down text-danger";
        }
        this.series = [];
        this.series.push({
          name: `No. of ${type}`,
          data: this.graphCustomers.users.map((go) => go.count),
        });
        // this.chartOptions.title.text = "Customer Statistics";
      } else if (type === "Delivery") {
        this.chartOptions.xaxis.categories = this.graphDeliveries.orders.map(
          (go) => go.day
        );
        const { lastWeekOrders } = this.graphDeliveries;
        const weekly = this.graphDeliveries.orders.reduce(
          (acc, it) => acc + it.count,
          0
        );
        this.chartData = {
          type: type,
          daily: (weekly / 7).toFixed(1).toString(),
          weekly: weekly.toString(),
          lastWeek: lastWeekOrders,
        };
        if (weekly === lastWeekOrders) {
          this.chartData.trend = "fa-grip-lines text-info";
        } else if (weekly > lastWeekOrders) {
          this.chartData.trend = "fa-arrow-circle-up text-success";
        } else {
          this.chartData.trend = "fa-arrow-circle-down text-danger";
        }
        this.series = [];
        this.series.push({
          name: `No. of ${type}`,
          data: this.graphDeliveries.orders.map((go) => go.count),
        });
      }
    },
    perPageChange(val) {
      if (this.region === "PAKISTAN") {
        this.$store.dispatch(FETCH_ACTIVE_ORDERS, [
          (this.currentPage - 1) * val,
          val,
        ]);
      } else {
        this.$store.dispatch(FETCH_ACTIVE_ORDERS, [
          (this.currentPage - 1) * val,
          (this.currentPage - 1) * val + val,
        ]);
      }
    },
    changePage(val) {
      if (this.region === "PAKISTAN") {
        this.$store.dispatch(FETCH_ACTIVE_ORDERS, [
          (val - 1) * this.perPage,
          this.perPage,
        ]);
      } else {
        this.$store.dispatch(FETCH_ACTIVE_ORDERS, [
          (val - 1) * this.perPage,
          (val - 1) * this.perPage + this.perPage,
        ]);
      }
    },
    orderRowClicked(items) {
      this.modalData = this.activeOrders[items.index];
      setTimeout(() => {
        this.$refs.orderModal.showOrderModal();
      }, 100);
    },
    orderModalClosed() {
      this.orderModal = null;
    },
    setActiveTab1(event) {
      this.tabIndex = this.setActiveTab(event);
    },
    setActiveTab2(event) {
      this.tabIndex2 = this.setActiveTab(event);
    },
    /**
     * Set current active on click
     * @param event
     */
    setActiveTab(event) {
      // get all tab links
      const tab = event.target.closest('[role="tablist"]');
      const links = tab.querySelectorAll(".nav-link");
      // remove active tab links
      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
      }
      // set current active tab
      event.target.classList.add("active");
      // set clicked tab index to bootstrap tab
      return parseInt(event.target.getAttribute("data-tab"));
    },
  },
};
</script>
<style lang="scss">
.AreYouSure {
  background: #fff;
  position: relative;
  font-size: 16px;
  font-weight: 400;
}
.CancelButtonCheck {
  color: #fff;
  // background: #dc1828;
  padding: 5px 15px;
  margin-left: 10px;
  border-radius: 25px;
  display: flex;
  align-items: center;
}
.modal-open {
  overflow: visible !important;
  .dashboard-content {
    filter: blur(5px);
  }
}
.modal-backdrop {
  color: #fff;
}
.dashboard-content {
  .cards-container {
    .row {
      width: 100%;
      margin-right: 0;
      margin-left: 0;
      .col-3 {
        padding: 5px;
        &:first-child {
          padding-left: 0;
        }
      }
    }
  }
  .graph-container {
    background: #fff;
    padding: 10px;
    height: 100%;
    h2 {
      margin-left: 15px;
    }
    .graph-stats {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      .stats-box {
        display: flex;
        flex-direction: column;
        align-items: center;
        .title {
          color: #ccc;
        }
        .stats-content {
          font-weight: 600;
        }
      }
    }
  }
  ::v-deep .customers-table-head {
    background: #fff;
    tr {
      th {
        vertical-align: middle;
        color: #763fb8;
        &:nth-child(3) {
          width: 30%;
        }
      }
    }
  }
  tr {
    vertical-align: middle !important;
    &:hover {
      background: #763fb8;
      color: #fff;
    }
    td {
      vertical-align: middle !important;
      font-size: 12px;
      .data-cell {
        display: flex;
        align-items: center;
        &.order-amount {
          display: flex;
          justify-content: center;
          width: 100%;
          .value {
            background: #82f89d;
            border-radius: 25px;
            padding: 3px;
            font-weight: 700;
            color: #019b25;
            width: 75px;
            text-align: center;
          }
          .green {
            background: #82f89d;
            color: white;
          }
          .red {
            background: #dc18288a;
            color: white;
          }
          .dropdown-toggle {
            background-color: transparent !important;
            border: none !important;
            padding: 0px !important;
            color: white;
          }
        }
        &.type {
          padding: 3px;
          border-radius: 25px;
          font-weight: 700;
          display: flex;
          justify-content: center;
          width: 100%;
        }
        &.type.normal-type {
          background: #ffafb1;
          color: #e90008;
        }
        &.type.squad-type {
          background: #fff27b;
          color: #809900;
        }
        &.status-cell {
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          img {
            height: 20px;
            width: auto;
          }
        }
      }
    }
    td:nth-child(3) {
      width: 33%;
      .name-data-block {
        width: 100%;
        .image-block {
          width: 15%;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 50%;
          overflow: hidden;
          img {
            height: 40px;
            width: 40px;
            border-radius: 50%;
          }
        }
        .details-block {
          width: 85%;
          .user-name {
            padding: 2.5px 0;
            font-weight: 700;
          }
          .address {
            color: black;
          }
          .phone-number {
            color: black;
          }
        }
      }
    }
  }
}
</style>
