<template>
  <div>
    <table class="status-graph table table-borderless">
      <tr class="status-graph__row">
        <td>
          <span
            class="status-graph__circle"
            :class="
              order.status === 'Pending'
                ? `${circleClass}--blink`
                : `${circleClass}--done`
            "
          ></span>
        </td>
        <td class="status-graph__status">Accepted</td>
        <td>
          {{
            order.status === "Pending"
              ? "Pending"
              : $moment(order.timeAccepted).format("h:mm a")
          }}
        </td>
        <td></td>
      </tr>
      <template v-for="store in order.stores">
        <tr class="status-graph__row" :key="store.store">
          <td>
            <span
              class="status-graph__circle"
              :class="
                store.status === 'Picked' || store.status === 'Reached'
                  ? `${circleClass}--done`
                  : `${circleClass}--pending`
              "
            ></span>
          </td>
          <td class="status-graph__status">{{ store.name }}[Reach]</td>
          <td>
            {{ $moment(store.timeReached).format("h:mm a") }}
          </td>
          <td>
            {{
              store.status === "Reached" || store.status === "Picked"
                ? `Reached in ${$humanize(store.durationReached * 1000, {
                    units: ["m"],
                    round: true,
                  })}`
                : `Expected to reach in ${$humanize(
                    store.durationExpected * 1000,
                    {
                      units: ["m"],
                      round: true,
                    }
                  )}`
            }}
          </td>
        </tr>
        <tr class="status-graph__row" :key="store.store">
          <td>
            <span
              class="status-graph__circle"
              :class="
                store.status === 'Reached'
                  ? `${circleClass}--blink`
                  : store.status === 'Picked'
                  ? `${circleClass}--done`
                  : `${circleClass}--pending`
              "
            ></span>
          </td>
          <td class="status-graph__status">{{ store.name }}[Picked]</td>
          <td>
            {{
              store.status === "Picked"
                ? $moment(store.timePicked).format("h:mm a")
                : "Pending"
            }}
          </td>
          <td>
            {{
              store.status === "Picked"
                ? `Picked in ${$humanize(store.durationShopped * 1000, {
                    units: ["m"],
                    round: true,
                  })}`
                : `Expected to pick in ${$humanize(
                    store.durationExpected * 1000,
                    {
                      units: ["m"],
                      round: true,
                    }
                  )}`
            }}
          </td>
        </tr>
      </template>
      <tr class="status-graph__row">
        <td>
          <span
            class="status-graph__circle"
            :class="
              order.status === 'Delivered'
                ? `${circleClass}--done`
                : order.status === 'Reached Customer'
                ? `${circleClass}--blink`
                : `${circleClass}--pending`
            "
          ></span>
        </td>
        <td class="status-graph__status">{{ order.customer.name }}</td>
        <td>
          {{
            order.status === "Delivered" || order.status === "Reached Customer"
              ? $moment(order.timeReachedCustomer).format("h:mm a")
              : "Pending"
          }}
        </td>
        <td>
          {{
            order.status === "Delivered" || order.status === "Reached Customer"
              ? `Reached in ${$humanize(order.durationReachedCustomer * 1000, {
                  units: ["m"],
                  round: true,
                })}`
              : `Expected to reach in ${$humanize(
                  order.durationExpected * 1000,
                  {
                    units: ["m"],
                    round: true,
                  }
                )}`
          }}
        </td>
      </tr>
      <tr class="status-graph__row">
        <td>
          <span
            class="status-graph__circle"
            :class="
              order.status === 'Delivered'
                ? `${circleClass}--done`
                : `${circleClass}--pending`
            "
          ></span>
        </td>
        <td class="status-graph__status">Delivered</td>
        <td>
          {{
            order.status === "Delivered"
              ? $moment(order.timeDelivered).format("h:mm a")
              : "Pending"
          }}
        </td>
        <td>
          {{
            order.status === "Delivered"
              ? `Delivered in ${$humanize(order.durationDelivered * 1000, {
                  units: ["m"],
                  round: true,
                })}`
              : "Pending"
          }}
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
export default {
  name: "StatusGraphUS",
  props: {
    order: Object,
  },
  data: function () {
    return {
      circleClass: "status-graph__circle",
    };
  },
};
</script>

<style lang="scss" scoped></style>
