<template>
  <div class="card shadow-sm">
    <div class="card-body">
      <div class="card-hero">
        <div class="card-icon">
          <i class="fa" v-bind:class="iconClass"></i>
        </div>
        <span class="title-text">{{ title }}</span>
      </div>
      <div class="value-text">
        <span v-if="isMoney && region === 'PAKISTAN'">PKR </span>
        <span v-if="isMoney && region === 'US'">$ </span>
        {{ value }}
      </div>
      <div class="sub-text" v-if="subText !== null">{{ subText }}</div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "InfoCard",
  computed: {
    ...mapState({
      region: (state) => state.region.isRegion,
    }),
  },

  props: {
    iconClass: String,
    title: String,
    value: Number,
    isMoney: String,
    subText: String,
  },
};
</script>

<style lang="scss" scoped>
.card {
  width: 100%;
  border-radius: 3px;
  margin: 10px auto;
  height: 160px;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 1) 50%,
    rgba(63, 69, 76, 1) 50%,
    rgba(63, 69, 76, 1) 100%
  );
  background-size: 100% 200%;
  background-position: 0% 0%;
  color: #000;
  &:hover {
    background-position: 0% 100%;
    animation: 0.5s hover-animate-card;
    transform: scale(1.01);
    color: #fff;
    .card-icon {
      i {
        color: #fff;
      }
    }
    .title-text {
      color: #fff;
    }
    .sub-text {
      display: flex;
    }
    .value-text {
      padding-top: 15px;
      animation: 0.5s padding-animate;
    }
  }
  .card-body {
    padding: 25px 7px;
  }
  .card-hero {
    height: 70px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .card-icon {
    display: flex;
    width: 100%;
    justify-content: center;
    i {
      color: #74bbdb;
      font-size: 28px;
      padding: 15px;
      padding-top: 0;
    }
  }
  .title-text {
    color: #74bbdb;
    font-size: 14px;
    display: flex;
    width: 100%;
    justify-content: center;
    text-align: center;
    text-transform: uppercase;
  }
  .value-text {
    padding-top: 20px;
    text-align: center;
    font-size: 18px;
    font-weight: 400;
  }
  .sub-text {
    display: none;
    font-size: 10px;
    justify-content: center;
    text-align: center;
  }
}
@keyframes hover-animate-card {
  0% {
    transform: scale(1);
    background-position: 0% 0%;
  }
  100% {
    transform: scale(1.01);
    background-position: 0% 100%;
  }
}
@keyframes padding-animate {
  0% {
    padding-top: 20px;
  }
  100% {
    padding-top: 15px;
  }
}
</style>
